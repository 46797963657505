<template>
  <div class="app-box">
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month||0" @change="screenChange"></x-screen-tab>
    <x-scroll-view :top="0.5">
      <table v-if="xhrData&&xhrData.length>0" class="data-table double-table">
        <colgroup>
          <col width="40%">
          <col width="20%">
          <col width="40%">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>客户名称</span>
            </th>
            <th>
              <span>拜访次数</span>
            </th>
            <th>
              <span>累计拜访时长</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData" :key="index">
            <th scope="row">{{item.customerName}}</th>
            <td>{{item.visitCount}}</td>
            <td>{{item.visistHours}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import noDataBg from "@/components/noDataBg";
import { statisticsCustomer } from "@/api/reportCenter";

export default {
  data () {
    return {
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        staffId: this.$route.params.staffId,
        year: this.$route.params.year,
        month: parseInt(this.$route.params.month) || null
      }
    };
  },
  created () {
    document.title = "拜访情况统计";
    this.getDataList();
  },
  methods: {
    getDataList () {
      statisticsCustomer(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "拜访情况统计";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
